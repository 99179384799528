<template>
<v-container fluid fill-height>
    <v-card :loading="loading" :class="`align-self-stretch ma-2 ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0 justify-space-between">
            <h1>Вивантаження для Prom.ua (beta)</h1>
        </v-card-title>
        <v-card-text class="pa-0 pt-4 body-1">
            <p>До вашої уваги новий сервіс В2В-портала - API прайс-листа у форматі YML для використання на маркетплейсі Prom.ua.</p>
            <p>Сервіс запущений в тестовому режимі. Тому побажання / виправлення просимо надсилати за адресою <a href="mailto:support@yugcontract.ua">support@yugcontract.ua</a>.</p>
        </v-card-text>
        <v-card-text class="pa-0 body-1">
            <v-row no-gutters>
                <v-col col="12" lg="6" class="d-flex align-top">
                    <v-text-field class="mt-4" label="Посилання для Prom.ua" type="text" filled readonly v-model="promuaUrl" :append-icon="promuaUrl ? `mdi-open-in-new` : ``" @click:append="goTo(promuaUrl)">
                    </v-text-field>
                    <v-btn class="ml-2 mt-7" icon @click="clickHandler(2)" title="Копіювати" v-clipboard="() => promuaUrl" v-clipboard:success="clipboardSuccessHandler">
                        <v-icon>{{copied[2] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="!promuaUrl" no-gutters>
                <v-col col="12" lg="6">
                    <v-alert type="info" outlined>Для формування посилання для Prom.ua необхідно заповнити форму Налаштування нижче і Зберегти.</v-alert>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-title class="px-0 pt-0 mt-4">Налаштування вивантаження:</v-card-title>
        <form>
            <v-row no-gutters>
                <v-col col="12" md="6">
                    <v-text-field v-model="f.shopName" label="* Назва магазину на Prom.ua"></v-text-field>
                    <v-text-field v-model="f.companyName" label="* Назва компанії на Prom.ua"></v-text-field>
                    <v-select multiple :items="type_prodItems" label="* Оберіть стан товару:" persistent-hint v-model="f.type_prod" clearable></v-select>
                    <v-select :items="typeItems" label="* Оберіть тип прайсу:" v-model="f.ptype"></v-select>
                    <v-card-actions v-if="$vuetify.breakpoint.mdAndUp">
                        <v-btn @click="saveShop()" :loading="loading" :disabled="formCheck" color="primary">Зберегти</v-btn>
                    </v-card-actions>
                </v-col>
                <v-col col="12" md="6" class="pl-0 pl-sm-6">
                    <div class="">Обмежити вивантаження лише вибраними групами каталогу:</div>
                    <div class="mb-4 ml-4 caption">* за замовченням вивантажується весь каталог товарів, який призначений для Prom.ua</div>
                    <v-treeview :open.sync="opened" v-model="selection" :items="items" selectable dense return-object selected-color="primary" class="ml-n2"></v-treeview>
                    <pre>
                    </pre>
                </v-col>
            </v-row>
        </form>
        <v-card-actions v-if="!$vuetify.breakpoint.mdAndUp">
            <v-btn @click="saveShop()" :loading="loading" :disabled="formCheck" color="primary">Зберегти</v-btn>
        </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data: () => ({
        copied: {},
        loading: false,
        f: {},
        selection: [{
            id: 0
        }],
        items: [],
        opened: [{
            id: 0
        }],
        type_prodItems: [{
                text: 'Новий',
                value: 1 //'new'
            },
            {
                text: 'Дефект упаковки (ДУ)',
                value: 2 //'du'
            },
            {
                text: 'Уцінка',
                value: 3 //'priceoff'
            },
        ],
        typeItems: [{
                text: 'Звичайний',
                value: 'regular'
            },
            {
                text: 'Безготівковий',
                value: 'bank'
            },
        ],

    }),
    methods: {
        ...mapActions(['setSnackbar', 'touch', 'createSecret', 'getSecretPare', 'getAllowedUserIp', 'getCatalogPromuaTree', 'savePromUA', 'getPromUa']),
        ...mapGetters(['userInfo']),
        createSecretPare() {
            this.touch()
                .then(() => {
                    this.createSecret()
                        .then(() => this.setSnackbar(['success', 'Нову пару ключів сгенеровано']))
                })
                .catch(error => console.error(error))
            this.dialog = false
        },
        clickHandler(v) {
            this.$set(this.copied, v, true)
            setTimeout(() => {
                this.copied[v] = false
            }, 1000);
        },
        clipboardSuccessHandler() {
            this.setSnackbar(['success', 'Скопійовано в буфер обміну'])
        },
        saveShop() {
            this.loading = true
            this.$set(this.f, 'cats', this.selectedCatIds)
            this.savePromUA(this.f)
                .then(data => {
                    this.setSnackbar(['success', 'Дані збережено'])
                    this.$set(this.f, 'id', data.id)
                    this.$set(this.f, 'user_key', data.user_key)
                    this.loading = false
                })
                .catch(error => {
                    this.setSnackbar(['error', 'Помилка збереження'])
                    this.loading = false
                })
        },
        setOpened() {
            this.opened.push()
        },
        goTo(url) {
            if (url) {
                window.open(url, '_blank')
            }
        }
    },
    computed: {
        secretPare() {
            return this.$store.state.main.secretPare
        },
        promuaUrl() {
            const url = process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/promua' : 'http://dev.yugcontract.ua:3033/api/promua'
            return this.f.user_key ? `${url}/${this.f.user_key}` : ``
        },
        selectedCatIds() {
            return this.selection ? this.selection.map(item => item.id) : []
        },
        formCheck() {
            return !this.f.shopName || !this.f.companyName || !(this.f.type_prod && this.f.type_prod.length) || !this.f.ptype
        }
    },
    created() {
        this.touch()
            .then(() => {
                this.loading = true
                this.getSecretPare()
                this.getAllowedUserIp()
                this.getCatalogPromuaTree()
                    .then(data => {
                        this.items.push({
                            id: 0,
                            name: 'Оберіть категорію товарів:',
                            children: []
                        })
                        for (let idx in data.menuItems) {
                            const item = data.menuItems[idx]
                            this.items[0].children.push({
                                id: item.link,
                                name: item.text,
                                children: []
                            })
                            for (let i in data.menuSubItems) {
                                if (i == item.link) {
                                    const elements = data.menuSubItems[i]
                                    for (let id in elements) {
                                        const el = elements[id]
                                        this.items[0].children[idx].children.push({
                                            id: el.link,
                                            name: el.text,
                                            children: []
                                        })
                                        if (data.menuSubItems[el.link]) {
                                            const elements = data.menuSubItems[el.link]
                                            for (let el of elements) {
                                                this.items[0].children[idx].children[id].children.push({
                                                    id: el.link,
                                                    name: el.text,
                                                    children: []
                                                })
                                            }
                                        }

                                    }
                                }
                            }
                        }
                        this.getPromUa()
                            .then(data => {
                                this.f = data
                                this.opened = [{
                                    id: 0
                                }]
                                this.selection = data.cats.map(id => {
                                    return {
                                        id
                                    }
                                })
                            })
                        this.loading = false

                    })

            })
            .catch(error => console.error(error))
    }
}
</script>
